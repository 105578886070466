

















































































































import VSwitch from "@/components/vuemarc-ui-kit/switch/VSwitch.vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import Vue from "vue";

import { DocumentData } from "./KYBRequirements/KYBExtraDocumentForm.vue";
import KYCRequirements from "../KYC/KYCRequirements/KYCRequirements.vue";
import KYBRequirementsExtraDocuments from "./KYBRequirements/KYBRequirementsExtraDocuments.vue";
import KYBVerificationRequirementsTabs from "./KYBRequirements/KYBVerificationRequirementsTabs.vue";
import KYBRequirementStep from "./KYBRequirements/KYBRequirementStep.vue";
import KYBRequirementsUBO from "./KYBRequirements/KYBRequirementsUBO.vue";
import KYBRequirementsCompanyInfo from "./KYBRequirements/KYBRequirementsCompanyInfo.vue";
import AppDetailEmailReminders from "../AppDetailEmailReminders.vue";
import { VerificationAPIType } from "@/models/verificationsTypes";

export default Vue.extend({
  components: {
    VSwitch,
    VTab,
    KYCRequirements,
    KYBRequirementsExtraDocuments,
    KYBVerificationRequirementsTabs,
    KYBRequirementStep,
    KYBRequirementsUBO,
    KYBRequirementsCompanyInfo,
    AppDetailEmailReminders,
  },
  name: "KYBVerificationRequirements",
  props: {
    cappFilteredSyncVerificationsAdmin: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    cappFilteredSyncVerificationsInputAdmin: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    cappFilteredSyncVerificationsInputUBO: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    cappFilteredSyncVerificationsUBO: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    recurrentAMLOptions: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    recurrentAMLAdminValue: { required: true, type: String },
    recurrentAMLUBOValue: { required: true, type: String },
    extraDocuments: {
      required: true,
      type: Array as () => Array<DocumentData>,
    },
    createMode: {
      required: true,
      type: Boolean,
    },
    verificationFlowConfig: { required: true, type: Object },
    verificationFlowPermissions: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    hasExtraDocuments: { required: true, type: Boolean },
  },
  watch: {
    "requirementsBooleans.hasUBOVerification": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("hasUBOVerification")(newVal);
      },
      immediate: true,
    },
    "requirementsBooleans.hasUBODiscovery": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("hasUBODiscovery")(newVal);
      },
      immediate: true,
    },
    "requirementsBooleans.hasUBODiscoveryPrefill": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("hasUBODiscoveryPrefill")(
          newVal
        );
      },
      immediate: true,
    },
    "requirementsBooleans.hasSanctionsCheck": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("hasSanctionsCheck")(newVal);
      },
      immediate: true,
    },
    "requirementsBooleans.hasMonitoringNotifications": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("hasMonitoringNotifications")(
          newVal
        );
      },
      immediate: true,
    },
    "requirementsBooleans.UBODiscoveryPercentage": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("UBODiscoveryPercentage")(
          newVal
        );
      },
      immediate: true,
    },
    "requirementsBooleans.UBODiscoveryDepthValue": {
      handler(newVal) {
        this.emitAndUpdateRequirementsBooleans("UBODiscoveryDepthValue")(
          newVal
        );
      },
      immediate: true,
    },
  },
  data() {
    return {
      activeTabName: "government-info-and-sanctions",
      requirementsBooleans: {
        hasUBODiscovery: this.verificationFlowConfig.ubo_discovery_enabled,
        hasUBOVerification:
          this.verificationFlowConfig.ubo_verification_enabled,
        hasSanctionsCheck:
          this.verificationFlowConfig.sanctions_screening_enabled,
        hasMonitoringNotifications:
          this.verificationFlowConfig.monitoring_notifications_enabled,
        UBODiscoveryPercentage:
          this.verificationFlowConfig.ubo_min_ownership_percentage,
        hasUBODiscoveryPrefill: this.verificationFlowConfig.ubo_prefill_enabled,
        UBODiscoveryDepthValue: this.verificationFlowConfig.ubo_tree_depth,
      } as Record<string, boolean | null>,
      verificationFlowPermissionsLocal: JSON.parse(
        JSON.stringify(this.verificationFlowPermissions)
      ),
    };
  },
  computed: {
    hasProofOfAddress: {
      get(): boolean {
        return this.verificationFlowPermissionsLocal?.some(
          (permissionsGroup: Array<VerificationAPIType>) =>
            permissionsGroup.includes("CPOA")
        );
      },
      set(value: boolean) {
        this.updateAndEmitVerificationFlowPermissions(value);
      },
    },
  },
  methods: {
    emitAndUpdateRequirementsBooleans(eventName: string) {
      return (newVal: boolean) => {
        this.$emit(eventName, newVal);
        if (!newVal && eventName !== "UBODiscoveryPercentage") {
          this.requirementsBooleans[eventName] = false;
        }
      };
    },
    updateAndEmitVerificationFlowPermissions(proofOfAddressValue: boolean) {
      if (!proofOfAddressValue) {
        this.verificationFlowPermissionsLocal =
          this.verificationFlowPermissionsLocal.filter(
            (permissionsGroup: Array<VerificationAPIType>) =>
              !permissionsGroup.includes("CPOA")
          );
      } else {
        this.verificationFlowPermissionsLocal.push(["CPOA"]);
      }
      this.$emit(
        "onHasProofOfAddressValueChange",
        this.verificationFlowPermissionsLocal
      );
    },
    resetUBORequirements() {
      this.requirementsBooleans = {
        hasUBODiscovery: false,
        hasUBOVerification: false,
        UBODiscoveryPercentage: null,
        hasUBODiscoveryPrefill: false,
      };
    },
  },
});
