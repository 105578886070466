

































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { fieldOptions, sectionValue } from "../../models/cdp/cdpModels";
import { PERCENTAGE_INPUTS } from "@/models/cdp";
import Decimal from "decimal.js";

export default Vue.extend({
  name: "PADetailCDPInput",
  props: {
    field: { required: true, type: Object as () => sectionValue },
    index: { required: true, type: Number },
    disabledDocumentSubtype: { required: false, default: false, type: Boolean },
    fieldType: { required: false, default: "TEXT", type: String },
    fieldOptions: { required: true, type: Object as () => fieldOptions },
    small: { required: false, type: Boolean, default: false },
    popoverPlacement: {
      required: false,
      type: String,
      default: "bottom-start",
    },
    mandatoryFieldsMissing: { required: false, type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    inputClasses(): any {
      return {
        "view-field": !this.fieldOptions.isLastVersionAndNotLocked,
        lighter:
          (this.fieldOptions.showDifferences &&
            !this.arePercentageValuesParsedAndDifferent) ||
          (!this.fieldOptions.activeUpdatedDataFileds.some((fieldKey) =>
            this.field.key.includes(fieldKey)
          ) &&
            this.fieldOptions.showChangeLog),
        error:
          (this.fieldOptions.commentKeys.length > 0 &&
            this.fieldOptions.commentKeys.some(
              (commentKey) => this.field.key === commentKey
            )) ||
          this.isMandatoryFieldAndValueIsMissing,
        "input-details__error":
          this.confidenceValue < 50 && this.confidenceValue !== 0,
        "input-details__warning":
          this.confidenceValue >= 50 && this.confidenceValue < 75,
        mandatory: this.field.mandatory,
        "extracted-information-detail__details-values-input__active":
          this.fieldOptions.activeInput === this.field.key,
        disabled:
          this.disabledDocumentSubtype &&
          this.field.key === "data.document_subtype.value" &&
          !this.fieldOptions.showDifferences,
        small: this.small,
      };
    },
    isMandatoryFieldAndValueIsMissing() {
      return (
        [
          "data.document_type.value",
          "data.document_number.value",
          "data.supplier_vat_code.value",
        ].includes(this.field.key) &&
        this.mandatoryFieldsMissing &&
        !this.field.value
      );
    },
    details(): TranslateResult {
      if (!this.fieldOptions.isLastVersionAndNotLocked) return "";
      else if (
        this.fieldOptions.showDifferences &&
        this.arePercentageValuesParsedAndDifferent
      )
        return this.$t("cpoa_section_dropdown_input_detail", {
          oldValue: this.parseValueIfPercentage(this.field.oldValue),
        });
      else if (this.confidenceValue < 75 && this.confidenceValue !== 0)
        return this.$t("label_confidence", {
          LabelConfidence: this.confidenceValue,
        });
      else {
        return "";
      }
    },
    fixedDecimals(): number {
      if (PERCENTAGE_INPUTS.includes(this.field.key)) return 2;
      return 0;
    },
    arePercentageValuesParsedAndDifferent(): boolean {
      return (
        this.parseValueIfPercentage(this.field.oldValue) !==
        this.parseValueIfPercentage(this.field.value)
      );
    },
    confidenceValue(): number {
      const confidence = new Decimal(this.field.confidence ?? 0);
      return parseFloat(confidence.toFixed(2));
    },
  },
  methods: {
    onChangeAndEmit(index: number, field: sectionValue) {
      this.$emit("onChange", {
        index: index,
        value: field.value,
      });
    },
    parsePopoverValue(value: string) {
      if (this.field.isDate) return this.$options?.filters?.date(value);
      return value;
    },
    parseValueIfPercentage(value: any) {
      if (this.fixedDecimals) {
        if (typeof value === "number") return value;
        if (
          value instanceof Decimal ||
          (value && value.constructor && value.constructor.name === "Decimal")
        )
          return new Decimal(value).toNumber().toFixed(2);
      }
      return value;
    },
  },
});
