













import Vue from "vue";

export default Vue.extend({
  name: "AnalyticsVerificationsSummaryItem",
  props: {
    summaryValue: { required: true, type: Number },
    status: { required: true, type: String },
    loading: { required: false, default: false, type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    isKYBFlow() {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "KYB"
      );
    },
    isCDPFlow() {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "CDP"
      );
    },
  },
  methods: {
    navigateAndFilter(status: string) {
      if (this.isKYBFlow)
        this.$router.push({
          name: "companies",
          query: {
            filters:
              status === "PENDING" ? `status=IN_PROGRESS` : `status=${status}`,
          },
        });
      else if (this.isCDPFlow)
        if (status === "NOT_PROCESSED" || status === "IN_PROGRESS") {
          this.$router.push({
            name: "complex-document-processor",
            query: { filters: `processing_status=RUNNING&processing_status=ERROR` },
          });
        } else {
          this.$router.push({
            name: "complex-document-processor",
            query: { filters: `status=${status}` },
          });
        }
    },
  },
});
