var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("mainnav_cdp")))]),_c('v-table',{ref:"CDPTable",staticClass:"clickable",attrs:{"headers":_vm.headers,"getRowsData":_vm.getCDPList,"filterOptions":_vm.filterOptions,"syncRowsData":_vm.syncRowsData,"hideDetails":true,"searchAdditionalParamsPropString":_vm.searchParamsProcessingString,"filtersMultiselectHeader":"filter_by_document_status","queryValue":"status"},on:{"updateSyncData":function($event){_vm.documentsIds = $event},"onRowClicked":function($event){return _vm.showAndGetCDPProcessingAttempt($event.processing_attempt_id)}},scopedSlots:_vm._u([{key:"status",fn:function(slotProps){return [_c('div',{class:("status_" + (slotProps.item.status))},[_vm._v(" "+_vm._s(slotProps.item.status && _vm.$t(slotProps.item.status.toLowerCase()))+" ")]),(slotProps.item.processing_status === 'RUNNING')?_c('div',{staticClass:"v-spinner loading v-table-spinner"}):_c('i',{staticClass:"cdp-list-icon",class:_vm.getDocumentProcessingStatusIcon(slotProps.item.processing_status)})]}},{key:"owner_user",fn:function(slotProps){return [(
          slotProps.item.owner_user &&
          !slotProps.item.owner_user.email.includes('siltapp')
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("clipped")(slotProps.item.owner_user.email,25))+" ")]):_c('span',[_vm._v("-")])]}},{key:"document_id",fn:function(slotProps){return [(slotProps.item.processing_attempt_id)?_c('span',[_vm._v(" "+_vm._s(_vm.truncateMiddleString(slotProps.item.processing_attempt_id))+" ")]):_c('span',[_vm._v("-")])]}},{key:"last_updated_by",fn:function(slotProps){return [(
          slotProps.item.reviewer_user &&
          !slotProps.item.reviewer_user.email.includes('siltapp')
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("clipped")(slotProps.item.reviewer_user.email,25))+" ")]):_c('span',[_vm._v("-")])]}},{key:"updated_at",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(slotProps.item.updated_at)))])]}},{key:"created_at",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(slotProps.item.created_at)))])]}},{key:"locked_by",fn:function(slotProps){return [(!!slotProps.item.locked_by)?[(
            slotProps.item.locked_by &&
            slotProps.item.locked_by.id !== _vm.$store.state.user.id
          )?[_c('v-popover',{attrs:{"placement":"bottom","delay":0,"trigger":"hover"}},[_c('i',{staticClass:"fad fa-user-lock"}),_c('p',{staticClass:"popover-inner hover-message",attrs:{"slot":"popover"},slot:"popover"},[_vm._v(" "+_vm._s((slotProps.item.locked_by && slotProps.item.locked_by.email) || "-")+" ")])])]:_c('span',[_vm._v(_vm._s(_vm.$t("you")))])]:_c('span',[_vm._v("-")])]}},{key:"link",fn:function(slotProps){return [_c('v-button',{staticClass:"v-icon small",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openDetailNewWindow(slotProps.item.processing_attempt_id)}}},[_c('i',{staticClass:"fad fa-external-link"})])]}}])},[_c('template',{slot:"v-table__options"},[(_vm.$store.state.userRole !== 'REVIEWER')?_c('VFilters',{attrs:{"header":"filter_by_processing_status","filterOptions":_vm.processingStatusOptions,"queryValue":"processing_status"},on:{"onFiltersSelectedChange":function($event){_vm.searchParamsProcessingString = $event}}}):_vm._e()],1)],2),_c('CDPModal',{ref:"CDPModal",attrs:{"loading":_vm.loading,"CDPDetail":_vm.CDPDetail,"user":_vm.userDetail,"showResourceDetail":_vm.showCDPDetail,"processingAttempt":_vm.processingAttempt,"pdfUrl":_vm.pdfUrl || '',"changelog":_vm.changeLog || [],"lastVersionDocumentProcessProps":_vm.lastVersionDocumentProcessProps,"isLockedBy":_vm.isLockedBy,"activeCDP":_vm.activeCDP,"isLastVersion":_vm.isLastVersion,"newVersionAvailable":_vm.newVersionAvailable},on:{"documentLocked":function($event){_vm.documentLocked = $event},"getCDPDocumentDetail":function($event){return _vm.getCDPDocumentDetail($event)},"getCDPDocumentDetailFromChangelog":function($event){return _vm.getCDPDocumentDetailFromChangelog($event)},"updateCDP":function($event){return _vm.updateCDPDocumentDetail($event)},"close":function($event){return _vm.onCloseCDPModal()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }