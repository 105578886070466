









import Vue from "vue";

export default Vue.extend({
  name: "v-tab",
  props: {
    activeTabName: { required: true, type: String },
    checkIfIsSelected: { required: true, type: String },
    amplitudeKeyEvent: { required: false, default: "" },
  },
  methods: {
    onClick() {
      this.$emit("onActiveTabChange", this.activeTabName);
      if (this.amplitudeKeyEvent)
        this.$root.$emit("amplitude", this.amplitudeKeyEvent);
    },
  },
});
