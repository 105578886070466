

















































































































import Vue from "vue";
import {
  VERIFICATIONS_TYPES_MAP,
  VerificationsMap,
} from "@/models/verificationsTypes";
import { PATypeStatus } from "@/lib/parsers";
import TabAttempts from "./TabAttempts.vue";

export default Vue.extend({
  components: { TabAttempts },
  name: "PATabs",
  props: {
    checksRequirements: { required: true, type: Array as () => Array<string> },
    resourcesRequirements: {
      required: true,
      type: Array as () => Array<string>,
    },
    processingAttempts: { required: true, type: [Array, Object] },
    resource: { required: true, type: Object },
    resourceDetail: { required: true, type: Object },
  },
  data() {
    return {
      VERIFICATIONS_TYPES_MAP,
      VerificationsMap,
      PATypeStatus: [] as any,
      activeRequirement: "",
      resourcesAttemptsFiltered: [] as any,
      checksAttemptsFiltered: [] as any,
      resourcesProcessingAttempts: [] as any,
      checksProcessingAttempts: [] as any,
    };
  },
  watch: {
    processingAttempts(newValue) {
      this.splitPAtoResourcesAndChecks(newValue);
    },
  },
  methods: {
    getTabStatusClass(requirement: string) {
      const tabStatus = this.PATypeStatus[requirement];
      if (!tabStatus) {
        return `status_BLOCKED disabled`;
      }
      return `status_${tabStatus}`;
    },
    getAttemptsOfRequirement(
      requirement: string,
      requirementVerifications?: any[]
    ) {
      this.resourcesAttemptsFiltered = this.resourcesProcessingAttempts.filter(
        (verification: any) => verification.permission_type === requirement
      );

      this.checksAttemptsFiltered = this.checksProcessingAttempts.filter(
        (verification: any) => verification.permission_type === requirement
      );

      this.activeRequirement = requirement;

      if (requirementVerifications) {
        const selectedRequirementFirstAttempt = requirementVerifications.find(
          (processingAttempt: any) =>
            processingAttempt.permission_type === requirement
        );
        this.$emit("getResourceDetail", selectedRequirementFirstAttempt);
      }
    },
    splitPAtoResourcesAndChecks(processingAttempts: any) {
      const auxChecksPas = [];
      const auxResourcesPas = [];
      for (const processingAttempt of processingAttempts) {
        if (
          this.resourcesRequirements.includes(
            processingAttempt.permission_type || processingAttempt.type
          )
        ) {
          auxResourcesPas.push(processingAttempt);
        } else {
          auxChecksPas.push(processingAttempt);
        }
      }
      this.resourcesProcessingAttempts = auxResourcesPas;
      this.checksProcessingAttempts = auxChecksPas;

      this.PATypeStatus = PATypeStatus(processingAttempts);

      if (this.resource) {
        this.getAttemptsOfRequirement(this.resource.permission_type);
      }
    },
  },
  mounted() {
    if (this.processingAttempts.length)
      this.splitPAtoResourcesAndChecks(this.processingAttempts);
  },
});
