













































import Vue from "vue";
import AnalyticsAllVerificationFlows from "./components/AnalyticsAllVerificationFlows.vue";
import AnalyticsVF from "./components/AnalyticsVF.vue";

export default Vue.extend({
  name: "Analytics",
  components: {
    AnalyticsAllVerificationFlows,
    AnalyticsVF,
  },
  data() {
    return {
      loading: false,
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
      groupBy: "day",
      verifications: { labels: [], values: [] } as any,
    };
  },
  computed: {
    groupByOptions(): Array<any> {
      return [
        { value: "day", text: this.$t("day") },
        { value: "week", text: this.$t("week") },
        { value: "month", text: this.$t("month") },
      ];
    },
    isAllVF(): boolean {
      return this.$store.state.companyAppId === "all";
    },
  },
  methods: {
    getRefAndLoadData(): void {
      if (!this.$refs) return;

      let ref: any = null;
      if (this.isAllVF) ref = (this.$refs as any).analyticsAllVF;
      else ref = (this.$refs as any).analyticsVF;

      if (ref && typeof ref.loadData === "function") {
        ref.loadData();
      }
    },
    getDates(startDate: Date, stopDate: Date): Array<string> {
      const dateArray = new Array();
      let currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate).toLocaleDateString());
        if (this.groupBy == "day") currentDate = currentDate.addDays(1);
        else if (this.groupBy == "week") currentDate = currentDate.addDays(7);
        else if (this.groupBy == "month") currentDate = currentDate.addDays(30);
      }
      return dateArray;
    },
    processResults(timePeriods: any) {
      if (!timePeriods) return;
      let lastDate = null;
      let labels: Array<string> = [];
      let values: Array<string> = [];
      const analytics = {
        labels: [] as Array<string>,
        values: [] as Array<string>,
      };
      const sortedResults = timePeriods.sort(
        (a: any, b: any) => new Date(a.to).getTime() - new Date(b.to).getTime()
      );
      if (
        sortedResults.length &&
        new Date(this.endDate).getTime() >
          new Date(sortedResults.at(-1).to).getTime()
      ) {
        timePeriods.push({ to: this.endDate, count: 0 });
      }
      for (let result of sortedResults) {
        if (!lastDate) {
          lastDate = new Date(result.to);
          labels = [lastDate.toLocaleDateString()];
          values = [result.count];
          continue;
        }
        const datesTemp = this.getDates(lastDate, new Date(result.to)).slice(1);
        labels = [...labels, ...datesTemp];
        let valuesTemp = [];
        if (datesTemp.length)
          valuesTemp = new Array(datesTemp.length - 1).fill(0);
        values = [...values, ...valuesTemp, result.count];
        lastDate = new Date(result.to);
      }
      if (this.groupBy === "month") {
        analytics.labels = labels.map((r: any) =>
          r.substring(0, 10).split("-").reverse().join("/").substring(3, 10)
        ) as never[];
      } else {
        analytics.labels = labels.map((r: any) =>
          r.substring(0, 10).split("-").reverse().join("/")
        ) as never[];
      }
      analytics.values = values as never[];

      return analytics;
    },
    getChartAnalytics(event: any) {
      if (!event.verifications.length) return;
      if (event.activeTabName) {
        const { time_periods } = event.verifications.find(
          (verification: any) => verification.type === event.activeTabName
        );
        this.verifications = this.processResults(time_periods);
        return;
      }
      this.verifications = this.processResults(event.verifications);
    },
  },
  mounted() {
    this.$root.$on("verificationFlowIdChanged", this.getRefAndLoadData);
    this.$root.$on("companyIdChanged", this.getRefAndLoadData);
  },
});
