




































































import Vue from "vue";
import KYBFormModal from "@/components/kyb/KYBFormModal.vue";
import KYBModal from "./components/KYBModal.vue";
import KYBService from "@/api/kyb/kybService";
import {
  VTableHeader,
  VTableInstance,
} from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import { COMPANY_STATUSES } from "@/models/verificationStatus";

export default Vue.extend({
  components: { KYBFormModal, KYBModal },
  name: "KYBCompaniesList",
  data() {
    return {
      showKYBFormModal: false,
      showKYBModal: false,
      loading: false,
      KYBCompanyDetail: {} as any,
      headers: [
        {
          name: "status",
          text: "status_title",
          style: "max-width: 180px",
        },
        {
          name: "company_name",
          text: "companies_company_name_title",
        },
        {
          name: "contact_email",
          text: "companies_contact_email_title",
        },
        {
          name: "created_at",
          text: "created_date_title",
          class: "text-right shrinked",
        },
      ] as VTableHeader[],
      KYBCompaniesListPrefilled: [] as any,
      searchParamsPropString: "",
      filterOptions: COMPANY_STATUSES,
    };
  },
  computed: {
    isKYBFlow() {
      return this.$store.state.verificationFlow.flow_type === "KYB";
    },
  },
  methods: {
    onOpenKYBFormModal() {
      this.showKYBFormModal = true;
    },
    handleKYBFormModalEvent(event: boolean) {
      this.showKYBFormModal = event;
      if (!event) {
        const kybTableRef = this.$refs.KYBTable as VTableInstance;
        kybTableRef.getRows();
      }
    },
    getKYBCompanyPrefilled(KYBCompanyId: any) {
      if (this.KYBCompaniesListPrefilled.length)
        this.KYBCompanyDetail = this.KYBCompaniesListPrefilled.find(
          (KYBCompany: any) => {
            if (KYBCompany.id) {
              return KYBCompany.id === KYBCompanyId;
            }
          }
        );
      return {};
    },
    async showAndGetKYBCompany(KYBCompanyId: string) {
      if (KYBCompanyId) {
        this.getKYBCompanyPrefilled(KYBCompanyId);
        this.showKYBModal = true;
        this.loading = true;
        try {
          const currentKYBCompany = await KYBService.getKYBCompany(
            KYBCompanyId
          );
          this.KYBCompanyDetail = currentKYBCompany.data;
          if (KYBCompanyId && KYBCompanyId !== this.$route.params.companyId) {
            this.$router.push({
              name: "companies-detail",
              params: { companyId: KYBCompanyId },
            });
          }
        } catch (error) {
          this.$root.$emit("v-toast", {
            localizedKey: "unsucessful_feedback",
            type: "error-toast",
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async getKYBCompanies(params?: URLSearchParams) {
      if (!this.isKYBFlow) return;
      const KYBCompaniesList = await KYBService.getKYBCompanies(
        this.$store.state.companyAppId,
        params
      );

      this.KYBCompaniesListPrefilled = KYBCompaniesList.results;

      return KYBCompaniesList;
    },
  },
  async mounted() {
    if (this.$route.params.companyId) {
      this.KYBCompanyDetail = await KYBService.getKYBCompany(
        this.$route.params.companyId
      );
      await this.showAndGetKYBCompany(this.$route.params.companyId);
    }
  },
});
