
























































































import Vue from "vue";
import UBODiagramNode from "./UBODiagramNode.vue";
import UserModal from "@/components/users/UserModal.vue";
import { isUboInAnyChild } from "@/lib/parsers";

type StyleType =
  | "SUCCESS"
  | "PENDING"
  | "MANUAL_REVIEW"
  | "MANUAL_SUCCESS"
  | "MANUAL_ERROR"
  | "ERROR"
  | "PENDING"
  | "BLOCKED";

export default Vue.extend({
  components: { UBODiagramNode, UserModal },
  name: "UBODiagram",
  props: {
    KYBCompanyData: { required: true, type: Object },
    focusNode: { required: false, default: "", type: String },
  },
  watch: {
    focusNode() {
      this.drawDiagramLines();
    },
  },
  data() {
    return {
      isUboInAnyChild: isUboInAnyChild,
    };
  },
  computed: {
    statusClass() {
      const kybStatus = this.KYBCompanyData && this.KYBCompanyData.status;
      const isIgnored = this.KYBCompanyData.ignored;

      return {
        "fa fa-check-circle":
          kybStatus === "SUCCESS" || kybStatus === "MANUAL_SUCCESS",
        "fa fa-times-circle":
          kybStatus === "MANUAL_ERROR" || kybStatus === "ERROR",
        "fa fa-question-circle":
          kybStatus === "PENDING" || kybStatus === "MANUAL_REVIEW",
        [isIgnored
          ? "BLOCKED"
          : kybStatus === "PENDING"
          ? "IN_PROGRESS"
          : `status_${kybStatus}`]: true,
      };
    },
    isScreeningKYBCompany() {
      return this.KYBCompanyData.status === "SCREENING";
    },
  },
  methods: {
    recursiveDrawing() {
      let time = performance.now();
      const refreshRate = 20; // in ms
      const tick = (now: number) => {
        const elapsed = now - time;
        if (elapsed > refreshRate) {
          time = now;
          this.drawDiagramLines();
        }
        requestAnimationFrame(tick);
      };
      requestAnimationFrame(tick);
    },
    fix_dpi(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
      //get DPI
      let dpi = window.devicePixelRatio;
      //get CSS height
      //the + prefix casts it to an integer
      //the slice method gets rid of "px"
      let style_height = +getComputedStyle(canvas)
        .getPropertyValue("height")
        .slice(0, -2);
      //get CSS width
      let style_width = +getComputedStyle(canvas)
        .getPropertyValue("width")
        .slice(0, -2);
      //scale the canvas
      canvas.setAttribute("height", style_height * dpi + "");
      canvas.setAttribute("width", style_width * dpi + "");
      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    },
    drawLine(
      ctx: CanvasRenderingContext2D,
      p1: HTMLElement,
      p2: HTMLElement,
      style: StyleType
    ) {
      if (!ctx) return;
      ctx.lineWidth = 3;

      const colorSuccess = "rgb(74, 206, 110, 0.3)";
      const colorError = "rgb(255, 51, 132, 0.3)";
      const colorPending = "rgb(237, 128, 0, 0.3)";
      const colorInProgress = "rgb(0, 136, 255, 0.1)";
      const colorBlocked = "rgb(156, 163, 175, 0.1)";

      const colors = {
        SUCCESS: colorSuccess,
        PENDING: colorInProgress,
        MANUAL_ERROR: colorError,
        MANUAL_REVIEW: colorPending,
        MANUAL_SUCCESS: colorSuccess,
        ERROR: colorError,
        BLOCKED: colorBlocked,
      };

      ctx.strokeStyle = colors[style];
      const radius = 20; // Should be lower than element height
      const sourceX = p1.offsetLeft + p1.offsetWidth;
      const sourceY = p1.offsetTop + p1.offsetHeight / 2;
      const targetX = p2 && p2.offsetLeft + 2;
      const targetY = p2 && p2.offsetTop + p2.offsetHeight / 2; // TODO Weird positioning

      const nodeXSpacing = targetX - sourceX;
      const nodeYSpacing = targetY - sourceY;

      ctx.beginPath();
      ctx.moveTo(sourceX, sourceY);

      const finalRadius = Math.max(
        Math.min(
          Math.sqrt(radius * radius),
          Math.sqrt((nodeYSpacing / 2) * (nodeYSpacing / 2))
        ),
        0
      );

      const startSourceCurveX = sourceX + nodeXSpacing / 2 - finalRadius;
      const endSourceCurveY =
        nodeYSpacing > 0 ? sourceY + finalRadius : sourceY - finalRadius;
      // const startSourceCurveY = sourceY + nodeXSpacing / 2 - innerMidHeight
      if (startSourceCurveX > sourceX) {
        ctx.lineTo(startSourceCurveX, sourceY);
      }
      ctx.bezierCurveTo(
        startSourceCurveX,
        sourceY,
        startSourceCurveX + finalRadius,
        sourceY,
        startSourceCurveX + finalRadius,
        endSourceCurveY
      );
      const startTargetCurveY =
        nodeYSpacing > 0 ? targetY - finalRadius : targetY + finalRadius;
      ctx.lineTo(sourceX + nodeXSpacing / 2, startTargetCurveY);
      ctx.bezierCurveTo(
        targetX - nodeXSpacing / 2,
        startTargetCurveY,
        targetX - nodeXSpacing / 2,
        targetY,
        targetX - nodeXSpacing / 2 + finalRadius,
        targetY
      );
      if (targetX - nodeXSpacing / 2 + finalRadius < targetX) {
        ctx.lineTo(targetX, targetY);
      }
      ctx.stroke();
    },
    drawChildrenLines(ctx: CanvasRenderingContext2D, rootNode: Vue) {
      const auxNodes = (rootNode.$refs["tree-node"] as Array<Vue>) || [];
      let nodes: Array<Vue> = [];
      if (auxNodes.length) nodes = [...auxNodes];
      const auxAdmin = rootNode.$refs["tree-node-admin"];
      if (auxAdmin) nodes.push(auxAdmin as Vue);
      if (!nodes.length) return;
      for (const node of nodes) {
        this.drawLine(
          ctx,
          rootNode.$refs["label"] as HTMLElement,
          node.$refs["label"] as HTMLElement,
          node.$props?.uboData?.ignored
            ? "BLOCKED"
            : node.$props?.uboData?.status || "SUCCESS"
        );
        this.drawChildrenLines(ctx, node);
      }
    },
    drawDiagramLines() {
      const mainBranch = this.$refs["main-branch"] as HTMLDivElement;
      const canvas = this.$refs["diagram-canvas"] as HTMLCanvasElement;
      if (!canvas) return;
      canvas.style.width = mainBranch.clientWidth + "px";
      canvas.style.height = mainBranch.clientHeight + "px";
      canvas.width = mainBranch.clientWidth;
      canvas.height = mainBranch.clientHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      this.fix_dpi(canvas, ctx);
      let nodes: Array<Vue> = [];
      const treeNodes = this.$refs["tree-node"] as Array<Vue>;
      if (treeNodes) nodes = [...treeNodes];
      const auxAdmin = this.$refs["tree-node-admin"] as Vue;
      if (nodes) {
        if (auxAdmin) nodes.push(auxAdmin as Vue);
        for (const node of nodes) {
          this.drawLine(
            ctx,
            this.$refs["sourceNode"] as HTMLElement,
            node.$el as HTMLElement,
            node.$props?.uboData?.ignored
              ? "BLOCKED"
              : node.$props?.uboData?.status || "SUCCESS"
          );
          this.drawChildrenLines(ctx, node);
        }
      }
    },
  },
  mounted() {
    if (
      this.KYBCompanyData.admin ||
      (this.KYBCompanyData.ubo_list && this.KYBCompanyData.ubo_list.length)
    )
      this.recursiveDrawing();
  },
});
