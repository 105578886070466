import { render, staticRenderFns } from "./PADetailRepresentative.vue?vue&type=template&id=bceb0ab2&scoped=true&"
import script from "./PADetailRepresentative.vue?vue&type=script&lang=ts&"
export * from "./PADetailRepresentative.vue?vue&type=script&lang=ts&"
import style0 from "./PADetailRepresentative.vue?vue&type=style&index=0&id=bceb0ab2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bceb0ab2",
  null
  
)

export default component.exports