export type VerificationType =
  | "passport"
  | "nationalId"
  | "drivingLicense"
  | "misconduct"
  | "rule"
  | "representative"
  | "aml"
  | "pep"
  | "biocheck"
  | "userProofOfAddress"
  | "companyProofOfAddress"
  | "complexDocumentProcessor"
//TODO: Revisar NATIONAL_ID PASSPORT i DRIVING_LICENSE es diuen UDV no? No entenc
export type VerificationAPIType =
  | "PASSPORT"
  | "NATIONAL_ID"
  | "DRIVING_LICENSE"
  | "MISCONDUCT"
  | "RULE"
  | "REPRESENTATIVE"
  | "BIOCHECK"
  | "PEP"
  | "AML"
  | "UPOA"
  | "CPOA"
  | "UDV"
  | "CDP";

export type VerificationAnalyticsResult = {
  type: VerificationAPIType | "REPR";// TODO: Fix and understand what the values of type should properly be
  totalCount: number;
  time_period: { from: Date; to: Date; count: number }[];
};


export const VERIFICATIONS_TYPES_MAP: { [key: string]: VerificationType } = {
  PASSPORT: "passport" as VerificationType,
  DRIVING_LICENSE: "drivingLicense" as VerificationType,
  NATIONAL_ID: "nationalId" as VerificationType,
  PEP: "pep" as VerificationType,
  AML: "aml" as VerificationType,
  BIOCHECK: "biocheck" as VerificationType,
  MISCONDUCT: "misconduct" as VerificationType,
  RULE: "rule" as VerificationType,
  REPRESENTATIVE: "representative" as VerificationType,
  UPOA: "userProofOfAddress" as VerificationType,
  CPOA: "companyProofOfAddress" as VerificationType,
  COMPLEX_DOCUMENT_PROCESSING: "complexDocumentProcessor" as VerificationType
};

export const VERIFICATIONS_TYPES_VIEW_MAP: { [key: string]: string } = {
  PASSPORT: "passport",
  DRIVING_LICENSE: "driving_license",
  NATIONAL_ID: "national_id",
  UPOA: "user_proof_of_address",
  CPOA: "company_proof_of_address",
  COMPLEX_DOCUMENT_PROCESSING: "document_processor"
};

export const KYC_VERIFICATIONS_TYPES_VIEW_MAP: { [key: string]: string } = {
  PASSPORT: "passport",
  DRIVING_LICENSE: "driving_license",
  NATIONAL_ID: "national_id",
  UPOA: "user_proof_of_address",
};

export const AML_VERIFICATIONS_TYPES_VIEW_MAP: { [key: string]: string } = {
  PEP: "pep" as VerificationType,
  AML: "aml" as VerificationType,
};

export const VERIFICATIONS_TYPES_API_MAP: {
  [key: string]: VerificationAPIType;
} = {
  passport: "PASSPORT" as VerificationAPIType,
  drivingLicense: "DRIVING_LICENSE" as VerificationAPIType,
  nationalId: "NATIONAL_ID" as VerificationAPIType,
  biocheck: "BIOCHECK" as VerificationAPIType,
  misconduct: "MISCONDUCT" as VerificationAPIType,
  rule: "RULE" as VerificationAPIType,
  representative: "REPRESENTATIVE" as VerificationAPIType,
  userProofOfAddress: "UPOA" as VerificationAPIType,
  companyProofOfAddress: "CPOA" as VerificationAPIType,
  complexDocumentProcessor: "COMPLEX_DOCUMENT_PROCESSING" as VerificationAPIType
};

export type VerificationTypeMapped = {
  [key in VerificationType]: { [key: string]: string };
};

export const VerificationsMap: VerificationTypeMapped = {
  complexDocumentProcessor: {
    icon: "fa-file-invoice",
    key: "complexDocumentProcessor",
    uploadType: "fromDisk",
    label: "mainnav_cdp",
    shortKey: "CDP",
  },
  companyProofOfAddress: {
    icon: "fa-home-lg-alt",
    key: "companyProofOfAddress",
    uploadType: "fromDisk",
    label: "upoa_title_overview",
    shortKey: "CPOA",
  },
  userProofOfAddress: {
    icon: "fa-home-lg-alt",
    key: "userProofOfAddress",
    uploadType: "fromDisk",
    label: "upoa_title_overview",
    shortKey: "UPOA",
  },
  nationalId: {
    icon: "fa-id-card",
    key: "nationalId",
    uploadType: "camera",
    label: "ID",
    shortKey: "ID",
  },
  misconduct: {
    icon: "fa-badge-sheriff",
    key: "misconduct",
    uploadType: "dashboardManual",
    label: "users_detail_checks_type_misconduct",
    shortKey: "Misconduct",
  },
  rule: {
    icon: "fa-code-branch fa-rotate-90-custom",
    key: "rule",
    uploadType: "dashboardManual",
    label: "mainnav_rules",
    shortKey: "Rules",
  },
  representative: {
    icon: "fa-user",
    key: "repr",
    uploadType: "dashboardManual",
    label: "companies_detail_representative_check_type_representative",
    shortKey: "Repr",
  },
  passport: {
    icon: "fa-passport",
    key: "passport",
    uploadType: "camera",
    label: "passport",
    shortKey: "Pass",
  },
  drivingLicense: {
    icon: "fa-car-alt",
    key: "drivingLicense",
    uploadType: "camera",
    label: "DL",
    shortKey: "DL",
  },
  aml: {
    icon: "fa-address-book",
    key: "aml",
    uploadType: "text",
    label: "AML",
    shortKey: "AML",
  },
  pep: {
    icon: "fa-address-book",
    key: "pep",
    uploadType: "text",
    label: "PEP",
    shortKey: "PEP",
  },
  biocheck: {
    icon: "fa-portrait",
    key: "biocheck",
    uploadType: "camera",
    label: "Biocheck",
    shortKey: "BIOCH",
  },
};
