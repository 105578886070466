import Vue from 'vue';
import moment from 'moment';

Vue.filter('date', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});

Vue.filter('dateTime', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm');
  }
});

Vue.filter('time', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('HH:mm');
  }
});