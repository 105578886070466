










































































































































import CDPService, {
  filterDeletedItemsAndReorderRows,
} from "@/api/cdp/cdpService";
import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import ResourcesService from "@/api/resources/resourcesService";
import CDPModal from "@/components/cdp/CDPModal.vue";
import {
  VTableHeader,
  VTableInstance,
} from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import { truncateMiddleString } from "@/lib/parsers";
import { handleBeforeUnload } from "@/lib/utils";
import {
  CdpDefaultSkeleton,
  CDP_DEFAULT_SKELETON,
  LockedBy,
} from "@/models/cdp";
import { CDP_STATUSES } from "@/models/verificationStatus";
import Vue from "vue";

export default Vue.extend({
  components: { CDPModal },
  name: "CDPList",
  data() {
    return {
      loading: false,
      truncateMiddleString: truncateMiddleString,
      headers: [
        {
          name: "status",
          text: "cdp_table_stauts_processing",
          style: "max-width: 150px",
        },
        {
          name: "owner_user",
          text: "owner_user",
        },
        {
          name: "document_id",
          text: "cdp_table_document_id",
        },
        {
          name: "last_updated_by",
          text: "cdp_table_last_updated_by",
        },
        {
          name: "updated_at",
          text: "global_last_updated",
        },
        {
          name: "created_at",
          text: "cdp_table_created_date",
        },
        {
          name: "locked_by",
          text: "cdp_table_locked_by",
          class: "text-right shrinked",
          style: "min-width: 100px",
        },
        {
          name: "link",
          text: "link",
          class: "icon",
        },
      ] as VTableHeader[],
      newVersionAvailable: false,
      syncRowsData: [] as any,
      CDPDetail: {} as any,
      showCDPDetail: false,
      userDetail: {} as any,
      processingAttempt: {} as any,
      resourceFiles: [] as Array<any>,
      changeLog: [] as Array<any>,
      documentsIds: [] as Array<string>,
      lastVersionDocumentProcessProps: {
        status: "",
        updated_at: "",
        created_at: "",
        raw_data: { confidence: 0 },
        reviewer_user: {},
      } as Partial<CdpDefaultSkeleton>,
      documentLocked: false,
      isLockedBy: null as LockedBy,
      intervalIds: {
        documentId: null as ReturnType<typeof setInterval> | null,
        listId: null as ReturnType<typeof setInterval> | null,
      } as Record<string, ReturnType<typeof setInterval> | null>,
      processingStatusOptions: {
        ERROR: "ERROR",
        RUNNING: "RUNNING",
        SUCCESS: "SUCCESS",
      },
      searchParamsProcessingString: "",
    };
  },
  watch: {
    CDPDetail: {
      handler() {
        if (this.CDPDetail.id === "CDP_DEFAULT_SKELETON") return;
        if (this.isLastVersion && this.showCDPDetail)
          this.intervalIds.documentId = setInterval(async () => {
            const syncDocument = (await this.getCDPDetail(
              this.CDPDetail.id
            )) as CdpDefaultSkeleton;
            if (
              this.showCDPDetail &&
              syncDocument &&
              this.CDPDetail &&
              this.CDPDetail.id === syncDocument.id &&
              syncDocument.updated_at !== this.CDPDetail.updated_at &&
              this.CDPDetail.locked_by === syncDocument.locked_by &&
              this.changeLog &&
              this.changeLog.length === 1 &&
              syncDocument.processing_status !==
                this.CDPDetail.processing_status
            ) {
              this.newVersionAvailable = true;
            }
            this.isLockedBy = syncDocument.locked_by;
          }, 4000);
        else this.stopPolling("documentId");
      },
      deep: true,
    },
  },
  computed: {
    pdfUrl(): string {
      return (
        this.resourceFiles &&
        this.resourceFiles.length &&
        this.resourceFiles[0].file_url
      );
    },
    isCDPFlow() {
      return this.$store.state.verificationFlow.flow_type === "CDP";
    },
    filterOptions() {
      const options: {
        APPROVED: string;
        PENDING?: string;
        MANUAL_REVIEW: string;
        ERROR: string;
        DISCARDED: string;
      } = { ...CDP_STATUSES };

      if (!["STAFF", "OWNER", "ADMIN"].includes(this.$store.state.userRole)) {
        delete options.PENDING;
      }

      return options;
    },
    activeCDP(): string {
      return this.CDPDetail?.id || "";
    },
    isLastVersion(): boolean {
      const hasChangelog = this.changeLog && this.changeLog.length > 0;

      if (!hasChangelog || this.changeLog[0].id === "CDP_DEFAULT_SKELETON") {
        return false;
      }
      return this.changeLog[0].id === this.activeCDP;
    },
  },
  methods: {
    getDocumentProcessingStatusIcon(documentProcessingStatus: string) {
      if (!documentProcessingStatus) documentProcessingStatus = "BLOCKED";
      return {
        "fa fa-check-circle":
          documentProcessingStatus === "SUCCESS" ||
          documentProcessingStatus === "APPROVED",
        "fa fa-times-circle":
          documentProcessingStatus === "ERROR" ||
          documentProcessingStatus === "DISCARDED" ||
          documentProcessingStatus === "VERIFICATION_ERROR",
        "fa fa-question-circle":
          documentProcessingStatus === "PENDING" ||
          documentProcessingStatus === "MANUAL_REVIEW" ||
          documentProcessingStatus === "BLOCKED",
        [`${documentProcessingStatus}`]: true,
      };
    },
    openDetailNewWindow(id: string) {
      const url = `${process.env.VUE_APP_SILT_DASHBOARD_URL}/complex-document-processor/${id}?verification_flow_id=${this.$store.state.companyAppId}`;
      window.open(url, "_blank");
    },
    stopPolling(intervalName: string) {
      if (intervalName === "documentId") this.newVersionAvailable = false;
      const interval = this.intervalIds[intervalName];
      if (interval) {
        clearInterval(interval);
        this.intervalIds[intervalName] = null;
      }
    },
    async onCloseCDPModal() {
      this.showCDPDetail = false;
      this.newVersionAvailable = false;
      this.stopPolling("documentId");
      this.$router.push({ name: "complex-document-processor" });
      this.changeLog = [];
      this.CDPDetail = {};
      window.removeEventListener("beforeunload", handleBeforeUnload);

      if (this.isLockedBy?.id === this.$store.state?.user?.id)
        await (this.$refs.CDPModal as any).unlockInvoiceDocument();
    },
    async getCDPList(params?: URLSearchParams) {
      if (!this.isCDPFlow) return;
      const CDPsList = await CDPService.getCDPsList(
        this.$store.state.companyAppId,
        params,
        this.$store.state.userRole
      );
      this.documentsIds = CDPsList.results.map((item: any) => item.id);
      if (this.documentsIds?.length) this.startSyncDocumentsListPolling();
      return CDPsList;
    },
    async getProcessingAttempt(CDPId: string) {
      const processingAttempt =
        await ProcessingAttemptsService.getProcessingAttemptFromId(CDPId);
      return processingAttempt;
    },
    async showAndGetCDPProcessingAttempt(CDPId: string) {
      if (CDPId) {
        this.showCDPDetail = true;
        this.loading = true;
        try {
          const res = await this.getProcessingAttempt(CDPId);
          this.processingAttempt = res;

          this.userDetail = res.owner_user;
          this.resourceFiles = await this.getFiles(this.processingAttempt.id);
          if (res.document_id) await this.getCDPDocumentDetail(res.document_id);
          else {
            this.CDPDetail = CDP_DEFAULT_SKELETON;
            this.CDPDetail.status = this.processingAttempt.status;
            this.CDPDetail.updated_at = this.processingAttempt.updated_at;
            this.CDPDetail.changelog[0].status = this.CDPDetail.status;
            // TO REVIEW: OJU perque changelog ja no es torna al get PA.
            this.changeLog = this.CDPDetail.changelog;
          }
          if (CDPId && CDPId !== this.$route.params.cdpId) {
            this.$router.push({
              name: "cdp-detail",
              params: { cdpId: CDPId },
            });
          }
        } catch (error) {
          this.$root.$emit("v-toast", {
            localizedKey: "unsucessful_feedback",
            type: "error-toast",
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async getCDPDetail(CDPDocumentId: string) {
      const res = await CDPService.getCDPDetail(
        this.$store.state.verificationFlow.app_id,
        CDPDocumentId
      );
      return res;
    },
    async getChangelog(CDPDocumentId: string) {
      const res = await CDPService.getChangelog(
        this.$store.state.verificationFlow.app_id,
        CDPDocumentId
      );
      return res;
    },
    setLastVersionDocumentProcessProps(documentData: CdpDefaultSkeleton) {
      this.lastVersionDocumentProcessProps = {
        status: documentData.status,
        updated_at: documentData.updated_at,
        created_at: this.processingAttempt.created_at,
        reviewer_user: documentData.reviewer_user,
        raw_data: { confidence: documentData.raw_data?.confidence },
      };
    },
    async getCDPDocumentDetail(CDPDocumentId: string) {
      this.loading = true;
      try {
        const res = (await this.getCDPDetail(
          CDPDocumentId
        )) as CdpDefaultSkeleton;
        this.setLastVersionDocumentProcessProps(res);
        this.changeLog = await this.getChangelog(CDPDocumentId);
        this.CDPDetail = res;
        this.isLockedBy = res.locked_by;
        this.newVersionAvailable = false;
        this.loading = false;
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      }
    },
    getCDPDocumentDetailFromChangelog(CDPDocumentId: string) {
      const versionSelected = this.changeLog.find(
        (document) => document.id === CDPDocumentId
      );
      versionSelected.data = filterDeletedItemsAndReorderRows(
        versionSelected.data
      );
      this.CDPDetail = versionSelected;
    },
    async getFiles(PAId: string) {
      try {
        if (PAId) {
          const { data } = await ResourcesService.getResourceFiles(PAId);
          return data.files;
        }
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      }
    },
    async updateCDPDocumentDetail(CDPDocumentUpdated: CdpDefaultSkeleton) {
      try {
        this.stopPolling("documentId");
        const { data } = await CDPService.updateCDPDocument(
          this.$store.state.verificationFlow.app_id,
          CDPDocumentUpdated.id,
          CDPDocumentUpdated
        );
        this.changeLog = await this.getChangelog(data.id);
        this.getCDPDocumentDetailFromChangelog(data.id);
        this.getRowsFromCDPTableRef();
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      }
    },
    getRowsFromCDPTableRef() {
      if (!this.$refs) return;
      const cdpTableRef = this.$refs.CDPTable as VTableInstance;
      cdpTableRef.getRows();
    },
    async getSyncDocumentIds() {
      const res = await CDPService.getCDPsSync(
        this.$store.state.verificationFlow.app_id,
        this.documentsIds
      );
      const syncDocumentsArray = Object.keys(res).map((key) => ({
        id: key,
        created_at: res[key].created_at,
        error: res[key].error,
        locked_by: res[key].locked_by,
        owner_user: res[key].owner_user,
        processing_attempt_id: res[key].processing_attempt_id,
        processing_status: res[key].processing_status,
        reviewer_user: res[key].reviewer_user,
        status: res[key].status,
        updated_at: res[key].updated_at,
      }));

      return syncDocumentsArray;
    },
    startSyncDocumentsListPolling() {
      if (!this.intervalIds.listId)
        this.intervalIds.listId = setInterval(async () => {
          this.syncRowsData = await this.getSyncDocumentIds();
        }, 5000);
    },
    initVisibilityChangeListener() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) this.stopPolling("listId");
        else this.startSyncDocumentsListPolling();
      });
    },
  },
  async mounted() {
    this.initVisibilityChangeListener();
    this.$root.$on(
      "cdpProcessingAttemptUploadedSuccessfully",
      this.getRowsFromCDPTableRef
    );
    if (this.$route.params.cdpId) {
      await this.showAndGetCDPProcessingAttempt(this.$route.params.cdpId);
    }
  },
  beforeDestroy() {
    this.stopPolling("listId");
    this.stopPolling("documentId");
  },
});
