


































































































import Vue from "vue";
import { isUboInAnyChild } from "@/lib/parsers";

export default Vue.extend({
  name: "UBODiagramNode",
  props: {
    uboData: { required: true, type: Object },
    isAdmin: { required: false, default: false },
    focusNode: { required: false, default: "", type: String },
  },
  data() {
    return {
      isUboInAnyChild: isUboInAnyChild,
    };
  },
  methods: {},
  mounted() {},
});
