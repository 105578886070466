<template>
  <div v-if="verifications" class="oneline-verifications__wrapper">
    <span
      v-for="(mv_group, i) of verifications"
      :key="`${mv_group}-${i}`"
      class="listed-item hint"
    >
      <span v-if="i > 0" class="listed-item">{{ $t("and") }} </span>
      (
      <span
        v-for="(mv, j) of mv_group"
        :key="`${mv}-${j}`"
        class="verification_type__name"
      >
        <span v-if="j > 0" class="hint">{{ $t("or") }} </span>
        {{ $t(VERIFICATIONS_TYPES_VIEW_MAP[mv]) }}
      </span>
      )
    </span>
  </div>
</template>

<script>
import {
  AML_VERIFICATIONS_TYPES_VIEW_MAP,
  VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";
import Vue from "vue";

export default Vue.extend({
  name: "oneline-verifications",
  props: {
    verifications: { required: true },
    showAllVerifications: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      VERIFICATIONS_TYPES_VIEW_MAP: this.showAllVerifications
        ? {
            ...VERIFICATIONS_TYPES_VIEW_MAP,
            ...AML_VERIFICATIONS_TYPES_VIEW_MAP,
          }
        : VERIFICATIONS_TYPES_VIEW_MAP,
    };
  },
});
</script>

<style lang="sass" scoped>
@import '~@/styles/global/theme.sass'

.verification_type__name
  color: $color-grey--darken-3

.oneline-verifications__wrapper
  display: inline-block
  &.light
    .hint
      color: rgba(255, 255, 255, 0.6)
    .verification_type__name
      color: #fff
</style>