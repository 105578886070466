


























import Vue from "vue";
import KYBRequirementStepTab from "../components/KYBRequirementStepTab.vue";
import { VerificationAPIType } from "@/models/verificationsTypes";
import { TranslateResult } from "vue-i18n";

export interface Check {
  value: boolean;
  text: string;
}

interface KYBRequirementStepTab {
  title: string;
  checks: Array<Check>;
  isRemovable?: boolean;
  value: boolean;
  activeRequirementName: string;
}

interface StepsOptions {
  text: TranslateResult;
  value: string;
}

export default Vue.extend({
  components: { KYBRequirementStepTab },
  name: "KYBVerificationRequirementsTabs",
  props: {
    activeTabName: { required: true, type: String },
    requirementsBooleans: {
      required: true,
      type: Object,
    },
    representativePermissions: {
      required: true,
      type: Array as () => Array<VerificationAPIType[]>,
    },
    hasProofOfAddress: {
      required: true,
      type: Boolean,
    },
    hasExtraDocuments: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    stepsOptions(): StepsOptions[] {
      const options = [];
      if (!this.hasExtraDocuments)
        options.push({
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_extra_documents"
          ),
          value: "extra-documents",
        });
      if (!this.hasProofOfAddress)
        options.push({
          text: this.$t(
            "verification_flow_settings_verification_requirements_poa_title"
          ),
          value: "proof-of-address",
        });
      if (!this.requirementsBooleans.hasUBODiscovery)
        options.push({
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_UBO_title"
          ),
          value: "kyc-ubo",
        });
      return options;
    },
    steps(): KYBRequirementStepTab[] {
      return [
        {
          title:
            "verification_flow_settings_verification_requirements_kyb_checks_tab",
          checks: this.governmentInfoAndSanctionsChecks,
          isRemovable: false,
          value: true,
          activeRequirementName: "government-info-and-sanctions",
        },
        {
          title:
            "verification_flow_settings_verification_requirements_kyb_extra_documents",
          checks: [
            {
              value: this.hasExtraDocuments,
              text: "verification_requirements_kyb_config_flow_extra_documents",
            },
          ],
          value: this.hasExtraDocuments,
          activeRequirementName: "extra-documents",
        },
        {
          title:
            "verification_flow_settings_verification_requirements_poa_title",
          checks: [
            {
              value: this.hasProofOfAddress,
              text: "verification_requirements_kyb_config_flow_check_cpoa",
            },
          ],
          value: this.hasProofOfAddress,
          activeRequirementName: "proof-of-address",
        },
        {
          title:
            "verification_flow_settings_verification_requirements_kyb_representative",
          checks: this.checksRepresentative,
          isRemovable: false,
          value: true,
          activeRequirementName: "kyc-representative",
        },
        {
          title:
            "verification_flow_settings_verification_requirements_kyb_UBO_title",
          checks: this.checksUBOConfig,
          value: this.requirementsBooleans.hasUBODiscovery,
          activeRequirementName: "kyc-ubo",
        },
      ];
    },
    governmentInfoAndSanctionsChecks(): Array<Check> {
      return [
        {
          value: true,
          text: "verification_requirements_kyb_config_flow_check_company_active",
        },
        {
          value: this.requirementsBooleans.hasSanctionsCheck,
          text: "verification_requirements_kyb_config_flow_check_sanctions",
        },
        {
          value: this.requirementsBooleans.hasMonitoringNotifications,
          text: "verification_requirements_kyb_config_flow_monitoring_notifications",
        },
      ];
    },
    checksUBOConfig(): Array<Check> {
      return [
        {
          value: this.requirementsBooleans.hasUBODiscovery,
          text: "verification_requirements_kyb_config_flow_ubo_discovery",
        },
        {
          value: this.requirementsBooleans.hasUBODiscoveryPrefill,
          text: "verification_requirements_kyb_config_flow_automatic_ubo_discovery",
        },
        {
          value: this.requirementsBooleans.hasUBOVerification,
          text: "verification_requirements_kyb_config_flow_kyc_ubo",
        },
      ];
    },
    checksRepresentative(): Array<Check> {
      const auxChecksRepresentative = [
        {
          value: true,
          text: "verification_requirements_kyb_config_flow_doc_verification",
        },
        {
          value: true,
          text: "verification_requirements_kyb_config_flow_check_representative_identity_match",
        },
      ];
      for (const permissionGroup of this.representativePermissions) {
        for (const permission of permissionGroup) {
          auxChecksRepresentative.push({
            value: permission.includes("AML"),
            text: "verification_requirements_kyb_config_flow_AML_check",
          });
          auxChecksRepresentative.push({
            value: permission.includes("PEP"),
            text: "verification_requirements_kyb_config_flow_PEP_check",
          });
          auxChecksRepresentative.push({
            value: permission.includes("UPOA"),
            text: "verification_requirements_kyb_config_flow_POA",
          });
        }
      }

      return auxChecksRepresentative;
    },
  },
  methods: {
    deleteRequirementAndResetConfig(requirement: string) {
      if (requirement === this.activeTabName)
        this.$emit("onActiveTabChange", "government-info-and-sanctions");
      if (requirement === "extra-documents")
        this.$emit("resetExtraDocuments", false);
      if (requirement === "proof-of-address") this.$emit("resetCPOA", false);
      if (requirement === "kyc-ubo") this.$emit("resetUBO");
    },
    activateRequirementAndTabName(event: string) {
      if (event === "proof-of-address" && !this.hasProofOfAddress)
        this.$emit("activeProofOfAddress", true);
      if (event === "kyc-ubo" && !this.requirementsBooleans.hasUBODiscovery)
        this.$emit("activeUBODiscovery", true);
      this.$emit("onActiveTabChange", event);
    },
  },
});
