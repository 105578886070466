var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ubo-node-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showUBODetail', _vm.uboData)}}},[_c('div',{ref:"label",staticClass:"ubo-node-label",class:_vm.uboData.ignored
        ? 'BLOCKED'
        : _vm.uboData.status === 'PENDING'
        ? 'IN_PROGRESS'
        : _vm.uboData.status},[(_vm.uboData && !_vm.isAdmin)?_c('i',{staticClass:"ubo-node-appended-icon",class:_vm.uboData.type && _vm.uboData.type.length && _vm.uboData.type.includes('UBO')
          ? 'fa fa-user'
          : 'fa fa-building'}):_vm._e(),(
        _vm.isAdmin ||
        (_vm.uboData.type && _vm.uboData.type.length && _vm.uboData.type.includes('UBO'))
      )?_c('span',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm.isAdmin ? ("" + (_vm.$t("legal_representative") + ":")) : "")+" "),(!!_vm.uboData.user.first_name)?[_vm._v(" "+_vm._s(_vm._f("clean_cap")((_vm.uboData.user.first_name + " ")))+" ")]:_vm._e(),(!!_vm.uboData.user.last_name)?[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.uboData.user.last_name))+" ")]:_vm._e()],2):(
        _vm.uboData.type && _vm.uboData.type.length && !_vm.uboData.type.includes('UBO')
      )?_c('span',[(_vm.uboData.details && _vm.uboData.details.legal_name)?[_vm._v(" "+_vm._s(_vm._f("capitalized")(_vm.uboData.details.legal_name))+" ")]:(_vm.uboData.name)?[_vm._v(" "+_vm._s(_vm._f("capitalized")(_vm.uboData.name))+" ")]:[_vm._v(" "+_vm._s(("- (" + (_vm.uboData.contact.user && _vm.uboData.contact.user.email && _vm.uboData.contact.user.email.toLowerCase()) + ")"))+" ")]],2):_c('span',[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.uboData.name))+" ")])]),(_vm.uboData.admin || (_vm.uboData.ubo_list && _vm.uboData.ubo_list.length))?_c('div',{staticClass:"branch"},[(_vm.uboData.admin)?[(
          !_vm.focusNode ||
          (_vm.uboData.admin && _vm.uboData.admin && _vm.focusNode === _vm.uboData.admin.id)
        )?_c('UBODiagramNode',{ref:"tree-node-admin",class:{ active: _vm.focusNode === _vm.uboData.admin.id },attrs:{"uboData":_vm.uboData.admin,"isAdmin":true,"type":"individual","focusNode":_vm.focusNode},on:{"showUBODetail":function($event){return _vm.$emit('showUBODetail', $event)}}}):_vm._e()]:_vm._e(),_vm._l((_vm.uboData.ubo_list),function(uboChild){return [(_vm.isUboInAnyChild(uboChild, _vm.focusNode) || !_vm.focusNode)?_c('UBODiagramNode',{key:uboChild.id,ref:"tree-node",refInFor:true,class:{
          active: _vm.focusNode === uboChild.id,
        },attrs:{"uboData":uboChild,"focusNode":_vm.focusNode},on:{"showUBODetail":function($event){return _vm.$emit('showUBODetail', $event)}}}):_vm._e()]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }