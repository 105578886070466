
























































































import { Vue } from "vue-property-decorator";
import PADetailAmlPepDetail from "@/components/processing-attempts/pa-detail/components/PADetailAmlPepDetail.vue";
import PADetailMisconductDetail from "@/components/processing-attempts/pa-detail/components/PADetailMisconductDetail.vue";
import PADetailRuleDetail from "@/components/processing-attempts/pa-detail/components/PADetailRuleDetail.vue";
import PADetailGenericDetail from "@/components/processing-attempts/pa-detail/components/PADetailGenericDetail.vue";
import PADetailTechDetail from "@/components/processing-attempts/pa-detail/components/components/PADetailTechDetail.vue";
import { toUpperCase } from "@/lib/parsers";
import CompaniesService from "@/api/companies/companiesService";
import PADetailRepresentative from "./components/PADetailRepresentative.vue";
import PADetailPOA from "./components/PADetailPOA.vue";
import PADetailCDP from "./components/PADetailCDP.vue";
import { handleBeforeUnload } from "@/lib/utils";

export default Vue.extend({
  name: "PADetail",
  components: {
    PADetailGenericDetail,
    PADetailAmlPepDetail,
    PADetailRuleDetail,
    PADetailMisconductDetail,
    PADetailTechDetail,
    PADetailRepresentative,
    PADetailPOA,
    PADetailCDP,
  },
  props: {
    resourceDetail: { required: true, type: Object },
    user: { required: true },
    statusOptions: { required: true, type: Array },
    loadingProp: { required: true, type: Boolean },
    KYBCompanyPrincipals: { required: false, type: Array },
  },
  data: () => {
    return {
      loading: false,
    };
  },
  methods: {
    updateResource(event: Event) {
      this.$emit("onUpdateResource", event);
    },
    async patchIdentityDocument() {
      const identityDocumentData = toUpperCase({
        address: this.resourceDetail.identity_document.address,
        birth_date: this.resourceDetail.identity_document.birth_date,
        city: this.resourceDetail.identity_document.city,
        country: this.resourceDetail.identity_document.country,
        document_number: this.resourceDetail.identity_document.document_number,
        document_type: this.resourceDetail.document_type,
        expiration_date: this.resourceDetail.identity_document.expiration_date,
        first_name: this.resourceDetail.identity_document.first_name,
        last_name: this.resourceDetail.identity_document.last_name,
        nationality: this.resourceDetail.identity_document.nationality,
        personal_number: this.resourceDetail.identity_document.personal_number,
        province: this.resourceDetail.identity_document.province,
        sex: this.resourceDetail.identity_document.sex,
      });
      const identityDocumentId = this.resourceDetail.identity_document.id;
      try {
        this.loading = true;
        const res = await CompaniesService.patchIdentityDocument(
          identityDocumentId,
          identityDocumentData
        );
        this.$root.$emit("v-toast", {
          localizedKey: "successful_changes_saved_feedback",
          type: "success-toast",
        });
        window.removeEventListener("beforeunload", handleBeforeUnload);
      } catch (error) {
        const message =
          error &&
          error.response &&
          error.response.data &&
          error.response.data &&
          error.response.data.exception.replaceAll(/[\[\]\']/g, "");
        this.$root.$emit("v-toast", {
          text:
            this.$t("unsuccessful_detail_feedback", { details: message }) ||
            this.$t("unsuccessful_changes_saved_feedback"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
