













































import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "PADetailTechDetail",
  props: {
    resourceDetail: { required: true, type: Object },
    user: { required: false, type: Object },
    showJson: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      loadingRerun: false,
      dashboardUrl: process.env.VUE_APP_SILT_DASHBOARD_URL,
    };
  },
  methods: {
    async reprocessProcessingAttempt() {
      const processingAttemptId =
        this.resourceDetail.id || this.resourceDetail.processing_attempt_id;
      if (processingAttemptId) {
        this.loadingRerun = true;
        await ProcessingAttemptsService.reprocessProcessingAttempt(
          processingAttemptId
        );
        const res =
          await ProcessingAttemptsService.pollProcessingAttemptCMEStatus(
            processingAttemptId
          );
        this.$emit("getResourceDetail", res.data);
        this.loadingRerun = false;
      }
    },
  },
});
