

























































import KYBService from "@/api/kyb/kybService";
import Vue from "vue";
import VLineChart from "@/components/vuemarc-ui-kit/charts/VLineChart.vue";
import AnalyticsVerificationsSummaryItem from "./components/AnalyticsVerificationsSummaryItem.vue";
import ChartSkeleton from "@/components/skeletons/components/ChartSkeleton.vue";
import CDPService from "@/api/cdp/cdpService";
import UsersService from "@/api/users/usersService";
import { sendSentryError } from "@/lib/utils";
import VTabSkeleton from "@/components/skeletons/components/VTabSkeleton.vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";

interface companyVerifications {
  type: string;
  totalCount: number;
}

export default Vue.extend({
  name: "AnalyticsVF",
  components: {
    VLineChart,
    VTab,
    AnalyticsVerificationsSummaryItem,
    VTabSkeleton,
    ChartSkeleton,
  },
  props: {
    startDate: { required: true, type: [Date, String] },
    endDate: { required: true, type: [Date, String] },
    groupBy: { required: true, type: String },
    verifications: { required: true, type: Object },
  },
  watch: {
    activeTabName: {
      handler() {
        this.$emit("getChartAnalytics", {
          activeTabName: this.activeTabName,
          verifications: this.companyVerifications,
        });
      },
    },
  },
  data() {
    return {
      KYBVerificationsSummary: {
        SUCCESS: 0,
        MANUAL_REVIEW: 0,
        MANUAL_ERROR: 0,
        PENDING: 0,
      } as any,
      CDPVerificationsSummary: {
        APPROVED: 0,
        PENDING: 0,
        MANUAL_REVIEW: 0,
        ERROR: 0,
        DISCARDED: 0,
        NOT_PROCESSED: 0,
      } as any,
      loading: false,
      companyVerifications: [] as companyVerifications[],
      activeTabName: "",
    };
  },
  computed: {
    isKYBFlow(): boolean {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "KYB"
      );
    },
    isCDPFlow(): boolean {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "CDP"
      );
    },
    isKYCFlow(): boolean {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "UDV"
      );
    },
    computedCopies(): any {
      if (this.isKYBFlow) {
        return {
          totalTitle: "overview_total_new_companies_title",
          chartTitle: "overview_verified_compenies_title",
          nodeText: "mainnav_companies",
        };
      }
      if (this.isCDPFlow) {
        return {
          totalTitle: "overview_total_new_documents_title",
          chartTitle: "overview_verified_docs_title",
          nodeText: "mainnav_cdp",
        };
      }
      return {
        totalTitle: "overview_total_new_users_title",
        chartTitle: "overview_verified_users_title",
        nodeText: "mainnav_users",
      };
    },
    verificationsSummary(): any {
      if (this.isKYBFlow) return this.KYBVerificationsSummary;
      return this.CDPVerificationsSummary;
    },
  },
  methods: {
    getVerificationFlowSelectedPermissions(companyApps: any) {
      const companyApp = companyApps.find(
        (companyApp: any) =>
          companyApp.id === this.$store.state.verificationFlow.app_id
      );

      return companyApp?.verifications || null;
    },
    filterAndParseVerifications(verifications: Array<any>) {
      const updatedVerifications = [];
      let totalCountOfVerification = 0;
      for (const verification of verifications) {
        for (const timePeriod of verification.time_periods) {
          if (timePeriod.count !== 0)
            totalCountOfVerification += timePeriod.count;
        }

        if (totalCountOfVerification) {
          if (verification.type === "Verified Users") {
            verification.type = "verified_users";
            this.activeTabName = "verified_users";
          }

          verification.totalCount = totalCountOfVerification;
          updatedVerifications.push(verification);
          totalCountOfVerification = 0;
        }
      }
      return updatedVerifications;
    },
    async getCDPSummary(searchParams: URLSearchParams) {
      const { summary } = await CDPService.getCDPVerificationsSummary(
        searchParams,
        this.$store.state.verificationFlow.app_id
      );
      this.CDPVerificationsSummary.NOT_PROCESSED = 0;

      for (const key in summary) {
        if (key === "SUCCESS") {
          for (const successKey in summary[key])
            this.CDPVerificationsSummary[successKey] = summary[key][successKey];
        } else {
          for (const subKey in summary[key]) {
            this.CDPVerificationsSummary.NOT_PROCESSED += summary[key][subKey];
          }
        }
      }
    },
    async getKYBSummaryAndAnalytics(searchParams: URLSearchParams) {
      const { summary } = await KYBService.getCompanyVerificationsSummary(
        searchParams,
        this.$store.state.verificationFlow.app_id
      );

      for (const key in summary) {
        if (
          summary.hasOwnProperty(key) &&
          this.KYBVerificationsSummary.hasOwnProperty(key)
        ) {
          this.KYBVerificationsSummary[key] = summary[key];
        }
      }

      if (this.KYBVerificationsSummary.MANUAL_SUCCESS)
        this.KYBVerificationsSummary.SUCCESS +=
          this.KYBVerificationsSummary.MANUAL_SUCCESS;

      let { verifications } = await KYBService.getVerifiedCompanies(
        searchParams,
        this.$store.state.verificationFlow.app_id
      );

      this.loading = false;

      if (!verifications.length)
        verifications = [{ count: 0, from: this.startDate, to: this.endDate }];

      this.$emit("getChartAnalytics", { verifications: verifications });
    },
    async getKYCOrCDPAnalytics() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      endDate.setDate(endDate.getDate() + 1);
      const searchParams: URLSearchParams = new URLSearchParams();
      const fromDateString = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      const toDateString = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;

      searchParams.set("from_date", fromDateString);
      searchParams.set("to_date", toDateString);
      searchParams.set("group_by", this.groupBy);
      if (this.isCDPFlow) this.getCDPSummary(searchParams);
      const res = await UsersService.getTotalVerificationsFromCompany(
        searchParams,
        this.$store.state.company.id
      );
      return res;
    },
    async loadData() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      endDate.setDate(endDate.getDate() + 1);
      const searchParams: URLSearchParams = new URLSearchParams();
      const fromDateString = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      const toDateString = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;

      searchParams.set("from_date", fromDateString);
      searchParams.set("to_date", toDateString);
      searchParams.set("group_by", this.groupBy);

      this.loading = true;
      if (this.isKYBFlow) {
        this.getKYBSummaryAndAnalytics(searchParams);
        return;
      }
      if (this.isCDPFlow) {
        this.getCDPSummary(searchParams);
      }
      this.activeTabName = "";
      const companyInformation: any = await this.getKYCOrCDPAnalytics();
      const filteredVerifications = this.getVerificationFlowSelectedPermissions(
        companyInformation.company_apps
      );
      if (!filteredVerifications?.length) {
        sendSentryError(
          "AnalyticsKYCAndCDP: filteredVerifications is undefined"
        );
        return;
      }
      this.companyVerifications = this.filterAndParseVerifications(
        filteredVerifications
      );
      if (this.companyVerifications && this.companyVerifications.length) {
        this.activeTabName = this.activeTabName
          ? this.activeTabName
          : this.companyVerifications[0].type;
        this.$emit("getChartAnalytics", {
          activeTabName: this.activeTabName,
          verifications: this.companyVerifications,
        });
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadData();
  },
});
