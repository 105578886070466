import Vue from "vue";
import Router from "vue-router";

import Home from "./views/home/Home.vue";
import Onboarding from "./views/onboarding/Onboarding.vue";
import Contact from "./views/contact/Contact.vue";
import UsersList from "./views/users/UsersList.vue";
import ProcessingAttemptsList from "./views/processing-attempts/ProcessingAttemptsList.vue";
import Settings from "./views/settings/Settings.vue";
import VerificationFlowSettings from "./views/verification-flow-settings/VerificationFlowSettings.vue";
import HowTo from "./views/home/how-to/HowTo.vue";
import MisconductList from "./views/misconduct-list/MisconductList.vue";
import RulesPage from "./views/rules/RulesPage.vue";
import KYBCompaniesList from "./views/kyb/KYBCompaniesList.vue";
import Analytics from "./views/analytics/Analytics.vue";
import { getToken } from "./lib/http/api";
import store from "./state/store";
import { actionsT } from "./state/actions";
import { mutationsT } from "./state/mutations";
import CDPList from "./views/cdp/CDPList.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Onboarding,
      meta: { mainMenuDisabled: true },
    },
    {
      path: "/welcome",
      name: "onboarding",
      component: Onboarding,
      meta: { mainMenuDisabled: true },
    },
    {
      path: "/recover-password",
      name: "recover-password",
      component: Onboarding,
      meta: { mainMenuDisabled: true },
    },
    {
      path: "/create-password",
      name: "create-password",
      component: Onboarding,
      meta: { mainMenuDisabled: true },
    },
    {
      path: "/overview",
      name: "home",
      component: Home,
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
    },
    {
      path: "/analytics",
      name: "analytics",
      component: Analytics,
    },
    {
      path: "/verification-flow-settings",
      name: "verification-flow-settings",
      component: VerificationFlowSettings,
    },
    {
      path: "/how-to",
      component: HowTo,
      children: [
        {
          path: "",
          name: "how-to",
          component: HowTo,
        },
        {
          path: "getting-started",
          name: "getting-started",
          component: HowTo,
        },
        {
          path: "integration",
          name: "integration",
          component: HowTo,
        },
        {
          path: "overview",
          name: "overview",
          component: HowTo,
        },
        {
          path: "users-and-companies",
          name: "users-and-companies",
          component: HowTo,
        },
        {
          path: "rules-description",
          name: "rules-description",
          component: HowTo,
        },
        {
          path: "settings-description",
          name: "settings-description",
          component: HowTo,
        },
      ],
    },
    {
      path: "/users",
      component: UsersList,
      children: [
        {
          path: "",
          name: "users",
          component: UsersList,
        },
        {
          path: ":userid",
          name: "users-detail",
          component: UsersList,
        },
      ],
    },
    {
      path: "/processing-attempts",
      component: ProcessingAttemptsList,
      children: [
        {
          path: "",
          name: "processing-attempts",
          component: ProcessingAttemptsList,
        },
        {
          path: ":paid",
          name: "processing-attempts-detail",
          component: ProcessingAttemptsList,
        },
      ],
    },
    {
      path: "/misconduct-list",
      component: MisconductList,
      children: [
        {
          path: "",
          name: "misconduct-list",
          component: MisconductList,
        },
        {
          path: ":userId",
          name: "misconduct-list-user-detail",
          component: MisconductList,
        },
      ],
    },
    {
      path: "/companies",
      component: KYBCompaniesList,
      children: [
        {
          path: "",
          name: "companies",
          component: KYBCompaniesList,
        },
        {
          path: ":companyId",
          name: "companies-detail",
          component: KYBCompaniesList,
        },
      ],
    },
    {
      path: "/complex-document-processor",
      component: CDPList,
      children: [
        {
          path: "",
          name: "complex-document-processor",
          component: CDPList,
        },
        {
          path: ":cdpId",
          name: "cdp-detail",
          component: CDPList,
        },
      ],
    },
    {
      path: "/rules",
      component: RulesPage,
      children: [
        {
          path: "",
          name: "rules",
          component: RulesPage,
        },
        {
          path: ":userId",
          name: "rule-hits-list-user-detail",
          component: RulesPage,
        },
      ],
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // TODO: Documentation: https://tanstack.com/query/latest
  let isLoggedIn = !!getToken();

  const publicPages = [
    "/login",
    "/welcome",
    "/recover-password",
    "/create-password",
  ];

  if (!isLoggedIn) {
    if (!publicPages.includes(to.path)) {
      const redirectQuery = to.query
      redirectQuery.redirect_url = to.path

      const routeName = to.path === '/' ? 'login' : { name: 'login', query: redirectQuery };
      router.push(routeName);
    }
  } else {
    if (
      (from.query && from.query.redirect_url && (!store.state.companyAppId || store.state.companyAppId === "all")) ||
      to.query &&
      to.query.verification_flow_id &&
      (!store.state.companyAppId || store.state.companyAppId === "all")
    ) {
      const verificationFlowId = to.query.verification_flow_id ?? from.query.verification_flow_id;
      store.commit(mutationsT.UPDATE_COMPANY_APP_ID, verificationFlowId);
    }
    await store.dispatch(actionsT.FETCH_ALL);
    if (from.query && from.query.redirect_url && to.path !== from.query.redirect_url) {
      const redirectPath = from.query.redirect_url as string
      next(redirectPath)
    }
    if (
      store.state.companyAppId === "all" &&
      to.path !== "/overview" &&
      ![
        "/processing-attempts/",
        "/how-to/",
        "/contact",
        "/analytics",
        "/settings",
        "/recent-activity"
      ].some((element) => to.path.includes(element))
    ) {
      next("/overview");
    }
    if (store.state.verificationFlow && store.state.verificationFlow.flow_type) {
      const { flow_type } = store.state.verificationFlow;
      const { path } = to;
      if (
        flow_type === "UDV" &&
        (path.startsWith("/companies") || path.startsWith("/complex-document-processor"))
      ) {
        next("/users");
      } else if (
        flow_type === "KYB" &&
        (path.startsWith("/users") || path.startsWith("/complex-document-processor"))
      ) {
        next("/companies");
      } else if (
        flow_type === "CDP" &&
        (path.startsWith("/companies") || path.startsWith("/users"))
      ) {
        next("/complex-document-processor");
      } else {
        next();
      }
    }
  }
  next();
});

export function updateURLSessionQueryParams(to :any) {
  const queryStart: Record<string, string | null | undefined> = {
    verification_flow_id: store.state.companyAppId,
  };
  if (to.filters) {
    queryStart.filters = to.filters
  }
  if (to.redirect_url) {
    queryStart.redirect_url = to.redirect_url
  }
  const entries = Object.entries(queryStart);
  const nonEmptyOrNull = entries.filter(
    ([key, val]) => val !== "" && val !== null
  );
  const query = Object.fromEntries(nonEmptyOrNull);
  return query;
}

router.afterEach(async (to) => {
  if (
    store.state.companyAppId &&
    store.state.companyAppId !== "all" &&
    to.query.verification_flow_id !== store.state.companyAppId &&
    store.state.company !== "all-companies"
  ) {
    const query = updateURLSessionQueryParams(to.query);
    router.replace({ query });
  }
});
