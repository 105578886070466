





































































































import Vue from "vue";
import PADetailVerificationInfo from "@/components/processing-attempts/pa-detail/components/components/PADetailVerificationInfo.vue";
import imageZoom from "vue-image-zoomer";

export default Vue.extend({
  components: { imageZoom, PADetailVerificationInfo },
  name: "PADetailPOA",
  props: {
    resourceDetail: { required: true, type: Object },
    expectedInfo: { required: true, type: Object },
    statusOptions: { required: true },
  },
  data() {
    return {};
  },
  computed: {
    cmes(): any {
      return (
        (this.resourceDetail as any).cmes ||
        (this.resourceDetail as any).core_module_executions
      );
    },
    isCPOA(): boolean {
      return this.resourceDetail.permission_type === "CPOA";
    },
    cmePOAValidator(): any {
      if (!this.cmes) return;
      return this.cmes.find((cme: any) =>
        [
          "USER_PROOF_OF_ADDRESS_VALIDATOR",
          "COMPANY_PROOF_OF_ADDRESS_VALIDATOR",
        ].includes(cme.type)
      );
    },
    pdfUrl(): string {
      return (
        this.resourceDetail.files &&
        this.resourceDetail.files.length &&
        this.resourceDetail.files[0].s3_url
      );
    },
    extractedInvoiceOuput(): Array<any> {
      const extractedInvoiceOutput = [
        {
          key: "name",
          label: this.$t("companies_detail_poa_document_information_name"),
          result: this.cmePOAValidator.output.invoice_name || "-",
          status: this.getLabelStatus("name"),
        },
        {
          key: "address",
          label: this.$t("companies_detail_poa_document_information_address"),
          result: this.cmePOAValidator.output.invoice_address || "-",
          status: this.getLabelStatus("address"),
        },
        {
          key: "date",
          label: this.$t(
            "companies_detail_poa_document_information_document_date"
          ),
          result: this.cmePOAValidator.output.invoice_date || "-",
          status: this.getLabelStatus("date"),
        },
      ];
      extractedInvoiceOutput.forEach((info: any) => {
        info.feedback = this.getLabelFeedback(info.status, info.key);
      });
      return extractedInvoiceOutput;
    },
    expectedInformation(): Array<any> {
      const expectedInformation = [
        {
          key: "name",
          label: this.$t("companies_detail_poa_document_information_name"),
          result: this.isCPOA
            ? this.expectedInfo.legal_name.toLowerCase()
            : this.expectedInfo.first_name.toLowerCase() +
              " " +
              this.expectedInfo.last_name.toLowerCase(),
          status: this.getLabelStatus("name"),
        },
        {
          key: "address",
          label: this.$t("companies_detail_poa_document_information_address"),
          result: this.expectedInfo.address.toLowerCase() || "-",
          status: this.getLabelStatus("address"),
        },
      ];
      expectedInformation.forEach((info: any) => {
        info.feedback = this.getLabelFeedback(info.status, info.key);
      });
      return expectedInformation;
    },
  },
  methods: {
    getLabelStatus(item: string) {
      const errorMappings: any = {
        address: "E_ADDRESS_MISMATCH",
        name: this.isCPOA ? "E_COMPANY_NAME_MISMATCH" : "E_USER_NAME_MISMATCH",
        date: "E_DOCUMENT_TOO_OLD",
      };

      const errorCode = errorMappings[item];

      return this.cmePOAValidator.errors.includes(errorCode)
        ? "ERROR"
        : "SUCCESS";
    },
    getLabelFeedback(status: string, label: string) {
      return this.$t(
        `users_detail_poa_popover_${label}_${
          status === "SUCCESS" ? "match" : "mismatch"
        }`
      );
    },
  },
});
