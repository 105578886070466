import { formatUri, poll } from "@/api";
import { HTTP } from "@/lib/http/api";
import { FILE_STATUS } from "@/models/verificationStatus";
import axios from "axios";

type dataPostBodyType = {
  type: string,
  file_name: string,
  dynamic_file_type_id?: string
}

export default class FilesService {
  static async postFile(data: any) {
    if (!data) throw { error: "No data Provided" };

    const dataPostBody: dataPostBodyType = {
      type: data.type,
      file_name: data.file_name
    };

    if (data.dynamic_file_type_id) {
      dataPostBody.dynamic_file_type_id = data.dynamic_file_type_id;
    }

    const res: any = await HTTP.post(formatUri`v2/files/`, dataPostBody);
    res.data.body["file"] = data.file;

    const formDataBody = new FormData();

    formDataBody.append("key", res.data.body.key);
    formDataBody.append("AWSAccessKeyId", res.data.body.AWSAccessKeyId);
    formDataBody.append("policy", res.data.body.policy);
    formDataBody.append("signature", res.data.body.signature);
    formDataBody.append("file", res.data.body.file);

    await axios.post(res.data.upload_url, formDataBody, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 30000
    });

    return res;
  }

  static async getFile(fileId: string) {
    // Permissions to access only own files
    const res = await HTTP.get(formatUri`v2/files/${fileId}`);

    return res
  }

  static async getDashboardFile(fileId: string) {
    // Permissions to access third party files
    const res = await HTTP.get(formatUri`dashboard/files/${fileId}`);

    return res
  }
}
